@import "@fortawesome/fontawesome-svg-core/styles.css";

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.custom-body-class {
  overflow: auto !important;
  margin-right: 0px;
}
/*@font-face {
  font-family: "HeyBeibeh";
  src: url("https://fonts.cdnfonts.com/css/heybeibeh") format("truetype");
  font-display: swap;
}*/
::-webkit-scrollbar {
  width: 7px;
  overscroll-behavior: contain;
}
mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography{
  font-size: 1rem;
  font-family: "Roboto Serif";
}
::-webkit-scrollbar-thumb {
  background: #319197;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #319197;
}
::-webkit-scrollbar-thumb:vertical {
  transition: background-color 0.3s ease-in-out;
}

html,
body {
  height: 100%;
  margin: 0;
  font-family: "Roboto Serif", "Helvetica Neue", sans-serif;
}
.theme,
.theme1 {
  color: #029e9d !important;
  font-size: 18px;
}
#border,
.theme1 {
  font-size: 1.4rem !important;
  border-bottom: 2px solid #000;
}
.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography h2 {
  font-family: "Roboto serif";
  font-weight: 700;
}
.heading2 {
  color: #029e9d !important;
  font-weight: 600;
  font-size: 32px !important;
  font-family: "Roboto serif";
  margin-bottom: 2rem;
  line-height: 3rem;
}
.mat-expansion-panel {
  --mat-expansion-container-background-color: rgb(255, 255, 255);
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.85);
}
.mat-mdc-autocomplete-panel {
  --mat-autocomplete-background-color: white;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.85);
}
.mat-mdc-option {
  --mat-option-label-text-font: "Roboto serif", serif;
}
.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font-family: "Roboto serif";
  font-size: 22px;
}
.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font-family: "Roboto serif";
  font-size: 24px
}
.mat-option.mat-selected:not(.mat-option-disabled) {
  color: #319197; /* Remplacez 'your-color' par la couleur souhaitée */
}
.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #319197;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: #3190978c;
  --mat-tab-header-active-label-text-color: #319197;
  --mat-tab-header-active-ripple-color: #319197;
  --mat-tab-header-inactive-ripple-color: #319197;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: #319197;
  --mat-tab-header-active-focus-label-text-color: #319197;
  --mat-tab-header-active-hover-label-text-color: #319197;
  --mat-tab-header-active-focus-indicator-color: #319197;
  --mat-tab-header-active-hover-indicator-color: #319197;
}
.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: "Roboto serif", sans-serif;
  --mat-tab-header-label-text-size: 18px;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  --mat-option-selected-state-label-text-color: #141414;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary
  .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #141414;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #319197; /* Remplacez 'your-color' par la couleur souhaitée */
}
.mdc-text-field--filled:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-line-ripple::before {
  border-bottom-color: white;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #319197 !important;
}

.mat-mdc-checkbox .mdc-form-field {
  --mdc-theme-text-primary-on-background: black;
  font-family: "Roboto Serif", sans-serif;
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-focus-icon-color: #319197;
  --mdc-checkbox-selected-hover-icon-color: #319197;
  --mdc-checkbox-selected-icon-color: #319197;
  --mdc-checkbox-selected-pressed-icon-color: #319197;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(19, 19, 19, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(156, 156, 156, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #319197;
  --mdc-checkbox-selected-hover-state-layer-color: #319197;
  --mdc-checkbox-selected-pressed-state-layer-color: #319197;
  --mdc-checkbox-unselected-focus-state-layer-color: rgb(20, 2, 2);
  --mdc-checkbox-unselected-hover-state-layer-color: rgb(14, 14, 14);
  --mdc-checkbox-unselected-pressed-state-layer-color: rgb(19, 18, 18);
}
/* .mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5, */
.mat-typography h1 {
  font-size: 18px;
  color: #029e9d;
  font-family: "Roboto serif", sans-serif;
}
@media (max-width: 991px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 36px;
  }
}
